.catalogue {
  background-color: white!important;
  height: 100%;
  //overflow: hidden;
  .catalogue-view {
   // overflow: hidden;
    overflow-y: scroll;
    height: 100%;
    margin-bottom: 18vh;

    .grid-loader {
      padding: 10px;
      text-align: center;
    }
  }
}
