.p-dialog .p-dialog-header {

  padding: 0px!important;
  

}

.div-search{

  text-align:center;
  padding-top:0.4rem;
  
}

.input-search{

  border:1px solid #cd2c00;
}

.input-search-w{
  font-family: 'DIN Pro';
  border:1px solid #cd2c00;
  width: 100%;
}

.p-button.p-button-text{
color:black;

}

.p-button-bottom{
  padding:3px !important;
  
  }
  

