.catalogue-detail {
  /*fondo blanco*/
  color: black;
  background-color: white;
  /*fondo negro*/
  //color: white;
  .catalogue-detail-carousel {
   // text-align: center;
    .p-carousel-prev, .p-carousel-next {
      display: none;
    }

    .p-carousel-indicators .p-carousel-indicator.p-highlight .p-link {
      background-color: #cd2c00;
    }

    .p-carousel-indicators .p-carousel-indicator .p-link {
      border-radius: 20px;
      width: 1rem;
      height: 1rem;

      &:focus {
        box-shadow: 0 0 0 0.2rem #f968506b;
      }
    }
  }
 
  .name-detail
  {
    text-transform: uppercase!important;
    font-size: 5.5vw;
  }
  .show-more-anchor {
    color: #cd2c00// var(--bluegray-400)
  }

  .bussiness-address {
    font-size: 3.8vw;
    font-weight: 400;
    font-style: italic;
    text-transform: none!important;
    padding-bottom: 0px!important;
    margin-top: 0.1rem !important;
  }
  .bussiness-country {
    font-size: 3.6vw;
    font-weight: 600;
   color:#cd2c00;
    text-transform: none!important;
    margin-bottom: 0.2rem !important;
  }

  .description-detail
  {
    font-size: 4.8vw;
    font-weight: 500;
    //font-style: italic;
  }
  .bussiness-list {
    padding: 0;
    list-style: none;

    li, .contact-item {

      span:nth-child(2) {
        float: right;
      }
    }

    .contact-item {
      display: flex;
      justify-content: center;
      align-items: center;

      span:nth-child(1) {
        flex-grow: 1;
      }
    }
  }
}

.p-carousel .p-carousel-indicators {
  padding: 0rem!important;

}
.catalogue-detail-carousel .p-carousel-item
{
  height: 41vh; /*can be anything*/
 // width: 160px; /*can be anything*/
  display: inline-block;
 
  position: relative;
  background-color: white;  
  text-align: center;
}


.socialMediaLinks svg {
  font-size: 26px!important;
  margin: 0 12px 0 0;
  color: #867070!important;
  text-decoration: none;
}

.socialMediaLinks svg.fa-twitter { 
  color: #00aced;
}
.socialMediaLinks svg.fa-facebook { 
  color: #3b5998!important;
}
.socialMediaLinks svg.fa-whatsapp{ 
  color: #00bb2d!important; 
}
.socialMediaLinks svg.fa-instagram { 
  color: #8a3ab9!important; 
}
.socialMediaLinks a.fa-google-plus-g { 
  color: #d34836; 
}
/*.socialMediaLinks svg {
font-size: 26px;  
margin: 0 10px 0 0; 
color: #000000; 
text-decoration: none; 
background: #b7b6b6;
padding: 15px;
width: 26px;
text-align: center;
border-radius: 50%;*/
