
//bnt confirm and cancel
.p-d-flex{
.p-button.inscribe-btn{
    float: right;
    height: 100%;
    font-size: .7em;
    font-family: 'DIN Pro';
    font-weight: 600;
    font-size: x-small;
    width: 45%;
    border-radius: .65rem;

  }

  #cancel-btn{
   color: black;
    background-color: white;
    border-color: black;
    float: left;
  }

  #mid-div{

    width: 10%;
  }

  #confirm-btn{
    color: white;
    background-color:#cd2c00;
    border-color: #cd2c00;
    float: right;
  }
}

//text gris

.text-comment{
    font-size: .7em;
    color: #867070;
    font-family: 'DIN Pro';
  }

  