.promotions-carousel {

 .p-carousel-item
{
  height: 16vh; /*can be anything*/
 // width: 160px; /*can be anything*/
  display: inline-block;
 width: auto;
  position: relative;
  background-color: white;  
  text-align: center;
}

  .p-carousel-prev, .p-carousel-next {
    display: none;
  }

  .p-carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .p-carousel-indicators .p-carousel-indicator.p-highlight .p-link {
    background-color: #cd2c00;
  }

  .p-carousel-indicators .p-carousel-indicator .p-link {
    background-color: #000;
  }

  .p-carousel-indicators .p-carousel-indicator .p-link {
    border-radius: 20px;
    width: 1rem;
    height: 1rem;

    &:focus {
      box-shadow: 0 0 0 0.2rem #f968506b;
    }
  }
}
