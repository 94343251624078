.bottom-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 6.2vh;
  border-top: 1px solid black!important;
  background-color: white;
  z-index: 100;

  .p-toolbar {
   /* padding-top: 8px;
    padding-bottom: 8px;*/
    padding:0.2rem;
    display: flex;
    background-color: white;
    border: none;
    padding-bottom:1rem!important;
    justify-content: space-around;

  /*   .p-toolbar-group-left {
      flex-grow: 1; */

      .p-button.p-button-text {
        color: black;
        font-family: 'DIN Pro';
        font-weight: 600;
        font-size: small;
      //  padding-bottom: 0.1rem;
       // flex-grow: 1;
        padding-top: 3px;
        padding-bottom: 3px;
      }

      .p-button-bottom{
        padding:3px !important;
      }

      .p-button.p-button-text:focus {
     //   outline: 0 none;
    //   outline-offset: 0;
        color:#cd2c00;;
   //     border-style:solid;
  //      border-width:1px;
  //     box-shadow: 0 0 0 0.05rem #cd2c00;
    //   background-color:#cd2c00;
        // opacity: 0.5;
        padding: 0 !important;
    }
  //  }
  .p-toolbar-group-left
  {
    width: 100%!important;
    display: flex;
    justify-content: space-between;;
    align-items: center;
    text-align: center;
  }
  }
 
}
.p-button .p-button-icon-left{
  margin-right: 0px!important;
}