.p-component#side-bar-inscribe
{
  font-family: 'DIN Pro';
  color: #cd2c00;
 // margin-right: 5px;
 // box-shadow : 0 .1rem 1rem .25rem #cd2c00!important;
  width: 90vw;
  border-radius: .65rem;
}
hr{
 // box-shadow : 0 .1rem 1rem .25rem #cd2c00!important;
color:#cd2c00!important;
}
#icon-form{
  font-size: x-large;
}
.text-comment{
  font-size: 3.1vw!important;
  font-weight: 500;
}
.inscribe-btn
{
  font-size: 3.1vw!important;
  font-weight: 500;
}