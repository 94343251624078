.qr-scanner-view {
  img {
    opacity: 0.5;
    max-width: 95%;
    max-height: calc(98% - 100px);
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }
}
