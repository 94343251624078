.catalogue-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;

  @mixin round-borders {
    border-radius: 10px;
  }

  .catalogue-grid_column {
    padding-left: 10px; /* gutter size */
    padding-right: 10px; /* gutter size */
    background-clip: padding-box;

    &:nth-child(1) {
      padding-right: 5px;
    }

    &:nth-child(2) {
      padding-left: 5px;
    }

    .product-item {
      display: flex;
      align-items: center;
      @include round-borders;

      .image-container {
        @include round-borders;
        
        img {
          @include round-borders;
          width: 100%; 
        }
      }

      .product-detail, .product-action {
        width: 100%;
      }

      .product-detail {

         h5 {
           //#cd2c00
           color: black;
           font-size: 4.3vw;
         }

        .product-category {
          font-size: 3.5vw;
        //  line-height: 30%!important;
       //   color: #867070;
         color: black;
          height: calc(.6em * 6);
          overflow: hidden;
         // display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
         
        }
        .product-country {
          font-size: 3.1vw;
         // color: #867070;
          color:#cd2c00;
          font-weight: 600;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 0.2rem !important;
        }
      }
    }
  }
}
   .salon-title 
    {
      margin-bottom: 0.3rem !important;
      margin-top: 0.4rem!important;
    }

    .icon-grid-category
    {
      height: 5vw;
      width: 5vw;
      display: block;
      border: 1px solid #f13100; //color de los iconos
      border-radius:50%;
      margin-bottom: 4px;
      float: right;
      margin-left: 2px;
      margin-top: 4px !important;
    }

