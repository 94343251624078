/*//.categories-carousel {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  //box-shadow : 0 .1rem 1rem .25rem #cd2c00!important;
  .p-carousel-indicators {
    display: none;
  }

  .p-carousel-content {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;//#232323;

    .p-carousel-container {
      .p-link {
        margin: 0;
        color: #cd2c00;

        &:hover {
          background: none;
          color: #cd2c00;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .p-carousel-items-content {
        text-align: -webkit-center;
      }
    }
  
  }*/
  .p-carousel-indicators {
    display: none;
  }
.p-grid.p-nogutter{
  align-items: center;
  justify-content: center;
  margin-right: -0.5rem!important;
  margin-left: -0.5rem!important;
}
  


  .categories-carousel {
    border-top: 1px solid black;
    border-bottom: 1px solid black;

  }

  .categories-carousel_item {
    padding: 4.3px!important;
      width: 14vw;
      height: 17vw;
   // min-width: 60px;
    margin: 0px;
    .title-cat {
      font-family: 'DIN Pro';
      font-size: 3.1vw!important;
      font-weight: 500;
    //  margin-top: 11px;
      margin-bottom: 0;
      color: black;
   //   color: rgb(68,68,68);
      text-align: center;
      margin-top: 0.6vw;
    }
   

    .image {
     height: 9vw;
      width: 9vw;
      top: 0;
      //display: block;
      border: 1px solid #f13100; //color de los iconos
      border-radius:50%;
     /* width: 100px!important;
      height: auto;*/
      display: block;
  margin-left: auto;
  margin-right: auto;

    }
  }
 


