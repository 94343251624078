.catalogue-detail {
  /*fondo blanco*/
  color: black;
  background-color: white;
  /*fondo negro*/
  //color: white;
  .catalogue-detail-carousel {

    .p-carousel-prev, .p-carousel-next {
      display: none;
    }

    .p-carousel-indicators .p-carousel-indicator.p-highlight .p-link {
      background-color: #cd2c00;
    }

    .p-carousel-indicators .p-carousel-indicator .p-link {
      border-radius: 20px;
      width: 1rem;
      height: 1rem;

      &:focus {
        box-shadow: 0 0 0 0.2rem #f968506b;
      }
    }
  }
  
  .show-more-anchor {
    color: #cd2c00// var(--bluegray-400)
  }

  .bussiness-address {
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    text-transform: none!important;
  }

  .description-detail
  {
    font-size: 13px;
    font-weight: 400;
    //font-style: italic;
  }
  .bussiness-list {
    padding: 0;
    list-style: none;

    li, .contact-item {

      span:nth-child(2) {
        float: right;
      }
    }

    .contact-item {
      display: flex;
      justify-content: center;
      align-items: center;

      span:nth-child(1) {
        flex-grow: 1;
      }
    }
  }
}

.p-carousel .p-carousel-indicators {
  padding: 0rem!important;
  
}
.p-carousel-item
{

  background-color: white;  
}
.socialMediaLinks svg {
  font-size: 26px!important;
  margin: 0 10px 0 0;
  text-decoration: none;
  border-radius: 50%!important;
}

.name_user_photo{
  display: flex;
  align-items: center; /* aligns all the items vertically centered */
  justify-content: right; /* aligns all the items horizontally centered */
  margin-top: 2.5vw;
  margin-right: 2.5vw;
}

.stats_user{
  display: flex;
  align-items: center; /* aligns all the items vertically centered */
  justify-content: left; /* aligns all the items horizontally centered */
  margin-left: 2vw;
}


