.header-component {
  border-bottom: 1px solid black;
 // box-shadow : 0 .1rem 1rem .25rem #cd2c00!important;
 
  background-color: white;
  font-size: .8em;
  width: 100vw;
  height: 47px;
  padding: 5px;
  position: sticky;
  top: 0;
  z-index: 100;
  overflow: hidden!important;
  //position: fixed;
  img {
    width: 45%;
    height: 100%;
  }

  .p-button.addme-btn {
   // float: right;
    background-color: white;
    border-color:#cd2c00;
    color: #cd2c00;
    height: 100%;
    font-size: 4vw!important;
    font-family: 'DIN Pro';
    font-weight: 600;
    font-size: x-small;
    width: 45%;
     &:hover {
      background-color: white;
      border-color: #cd2c00;
      color:#cd2c00;
    }
    &:focus {
      box-shadow: none;
    } 
  }

  .p-button.locale-btn {
    // float: right;
     background-color: white;
     text-align: center!important;
     border-color:#cd2c00;
     color: #cd2c00;
     height: 100%;
     font-size: 10vw;
     font-family: 'DIN Pro';
     font-weight: 600;
     font-size: x-small;
     width: 5%;
      &:hover {
       background-color: white;
       border-color: #cd2c00;
       color:#cd2c00;
     }
     &:focus {
       box-shadow: none;
     } 
   }

  .pi-home
  {

    color: #cd2c00;    
  }


}
