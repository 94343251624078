@import 'primereact/resources/themes/saga-blue/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.css';
@import 'typeface-montserrat/index.css';

body {
    margin: 0;
    font-family: 'DIN Pro';
}

