@charset "UTF-8";

/* @font-face {
  font-family: 'Din Pro Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: local("Din Pro Condensed") url("DIN_Pro_Font_Family/DIN-Pro-Condensed-Regular-400.otf") format("opentype");
} 

:root {
  --bs-font-din-pro-condensed: 'DIN-Pro-Condensed-Regular-400';
  --bs-body-font-family: var(--bs-font-din-pro-condensed);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent
}*/

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro Condensed Light'), local('DIN-Pro-Condensed-Light'),
      url('DIN_Pro_Font_Family/DINPro-CondensedLight/DINPro-CondensedLight.woff2') format('woff2'),
      url('DIN_Pro_Font_Family/DINPro-CondensedLight/DINPro-CondensedLight.woff') format('woff'),
      url('DIN_Pro_Font_Family/DINPro-CondensedLight/DINPro-CondensedLight.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

:root {
  --bs-font-din-pro-condensedlight: 'DIN Pro';
  --bs-body-font-family: var(--bs-font-din-pro-condensedlight);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  // --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  -webkit-text-size-adjust: 100%;
  letter-spacing: 0.3pt;
  -webkit-tap-highlight-color: transparent
}